import {useTranslation} from "react-i18next";

export default function Cartoz() {
    const {t} = useTranslation();

    return (
        <div className={'cartoz'}>
            <h3 className={'cartoz__name _mobile'}>{t('cartozName')}</h3>
            <div className={'cartoz__column cartoz__column_left'}>
                <div className={'cartoz__image'}>
                    <a href={'http://nicocartosio.com/press'} target={'_blank'} rel={'noreferrer noopener'} className={'press'}>
                        <div className={'press__pic'}/>
                        <div className={'press__text'}>press</div>
                    </a>
                </div>
            </div>
            <div className={'cartoz__column cartoz__column_right'}>
                <div className={'cartoz__data'}>
                    <h3 className={'cartoz__name _desktop'}>{t('cartozName')}</h3>
                    <div>
                        <p className={'cartoz__paragraph'}>
                            {t('cartozP1_1')}<br/>
                            {t('cartozP1_2')}<br/>
                            {t('cartozP1_3')}
                        </p>
                        {/*<p className={'cartoz__paragraph'}>
                            {t('cartozP2_1')}<br/>
                            {t('cartozP2_2')}
                        </p>
                        <ul className={'cartoz__paragraph'}>
                            <li>{t('cartozP3_1')}</li>
                            <li>{t('cartozP3_2')}</li>
                            <li>{t('cartozP3_3')}</li>
                        </ul>*/}
                    </div>
                    <div className={'cartoz__music'}>
                        <a target={'_blank'} rel="noreferrer" href={'https://www.instagram.com/nico.cartosio?igsh=Z28yNXJwanM5YXN1'} className={'cartoz-music__item cartoz__instagram'}> </a>
                        <a target={'_blank'} rel="noreferrer" href={'https://open.spotify.com/artist/2Uz3WcIlks19ypNOBPM4hi'} className={'cartoz-music__item cartoz__spotify'}> </a>
                        <a target={'_blank'} rel="noreferrer" href={'https://music.apple.com/ru/artist/nico-cartosio/1364461542?l=en-GB'} className={'cartoz-music__item cartoz__apple'}> </a>
                    </div>
                </div>
            </div>
        </div>
    )
}