import {useTranslation} from "react-i18next";

export default function About() {
    const {t} = useTranslation();

    return (
        <div className={'about'}>
            <div className={'about__column about__column_left'}>
                <h2 className={'about__headline'}>{t('aboutHeadline')}</h2>
                <div className={'about__text'}>
                    <p className={'about_paragraph'}>{t('aboutP1')}</p>
                    <p className={'about_paragraph _en'}>{t('aboutP1_1')}</p>
                    <div className={'about__quote'}>“</div>
                    <p className={'about_paragraph about_paragraph_quote'}>{t('aboutP2')}</p>
                    <span className={'about__author'}>Nico Cartosio</span>
                    <div className={'about__image _mobile'}/>
                    <p className={'about_paragraph about_paragraph_3'}>{t('aboutP3')}</p>
                </div>
            </div>
            <div className={'about__column about__column_right'}>
                <div className={'about__image _desktop'}/>
            </div>
        </div>
    )
}