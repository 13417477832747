import {useTranslation} from "react-i18next";
import {Menu} from "./Menu";
import Head from "./Head";

export default function Banner() {
    const {t} = useTranslation();

    return (
        <div className={'banner'}>
            <div className={'banner__shade'}></div>
            <div className={'App__content'}>
                <Menu/>
                <Head/>
                <div className={'banner__partners-wrap _mobile'}>
                    <div className={'banner__partners banner__partners_' + t('lang')}></div>
                </div>
                <div className={'logo logo_' + t('lang')}></div>
                <div className={'banner__texts'}>
                    <h2 className={'banner__compositor'}>{t('compositor')}</h2>
                    <h1 className={'banner__compositor-name'}>{t('compositorName')}</h1>
                    <span className={'banner__sold-out'}>sold out</span>
                </div>
            </div>
        </div>
    )
}