// noinspection JSCheckFunctionSignatures

import {useTranslation} from "react-i18next";

export default function Head() {
    const { t } = useTranslation();

    return (
        <header className={'header'}>
            <div className={'header__block header__block_left header-left'}>
                <div>
                    <span className={'header-left__text-1'}>{t('head1_1')}</span>
                    <span className={'header-left__text-2'}> {t('head1_2')}</span>
                </div>
                <div>
                    <span className={'header-left__text-3'}>{t('head1_3')}</span>
                    <span className={'header-left__text-4'}> {t('head1_4')}</span>
                </div>
            </div>
            <div className={'header__block header__block_right header-right'}>
                <span className={'header-right__text header-right__text_1'}>{t('head2_1')}</span>
                <span className={'header-right__text header-right__text_2'}>{t('head2_2')}</span>
                <span className={'header-right__text header-right__text_3'}> {t('head2_3')}</span>
            </div>
        </header>
    )
}