import {BrowserRouter} from "react-router-dom";
import Banner from "./components/Banner";
import About from "./components/About";
import Video from "./components/Video";
import Cartoz from "./components/Cartoz";
import {useTranslation} from "react-i18next";

function App() {
    const {t} = useTranslation();

    return (
        <BrowserRouter>
            <div className={"App App_" + t('lang')}>
                <Banner/>
                <div className={'App__content'}>
                    <About/>
                </div>
                <Video/>
                <div className={'App__content'}>
                    <Cartoz/>
                </div>
                <div className="cookie-notification cookie-notification_hidden_yes">
                    <div className="cookie-notification__body">
                        <p>{t('cookie')}</p>
                    </div>
                    <div className="cookie-notification__buttons">
                        <button className="cookie-notification__button cookie-notification__button_yes" id="yes">{t('cookieOk')}</button>
                        <button className="cookie-notification__button cookie-notification__button_no" id="yes">{t('cookieReject')}</button>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;