import {useTranslation} from "react-i18next";

export default function Video() {
    const {t} = useTranslation();
    //document.setT

    return (
        <div className={'video'}>
            <iframe width="100%" height="100%" src={t('youtube')}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
    )
}